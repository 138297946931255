// import useAuthStore from "../store/useAuthStore"

// const useCheckLogin = () => {
//     const { token, userData } = useAuthStore(state => state)

//     if (token == null && userData == null) {
//         return false;
//     }
//     if (token !== localStorage.getItem('token')) {
//         return false;
//     }
//     return typeof token === 'string' && Object.keys(userData).length > 0
// }

// export default useCheckLogin

import { useQuery } from "@tanstack/react-query";
import axios from "axios"

const useCheckLogin = () => {
    return useQuery(['checkLogin'], async () => {
        try {
            const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/method/frappe.auth.get_logged_user`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                }
            })
            if (res?.status === 200) {
                return true
            } else {
                console.log("Error: ", res)
                return false
            }
        } catch (e: any) {
            console.log("Error in useChecklogin: ", e)
            if(e?.response?.status === 403) {
                return false
            }
        }
    })
}

export default useCheckLogin
