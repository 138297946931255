import Heading2 from "@/lib/Layout/Wrappers/Heading2";
import { motion } from "framer-motion";
import React from "react";
import { MdOutlineAddCircle } from "react-icons/md";
import styles from "styles/styles.json";
//import Section from "../Wrappers/Section";
// import {LeadsForm } from "../../../sections/LeadsForm/LeadsForm";
import router, { useRouter } from "next/router";

type WhatWeTreat = {
  key: number; link: string; route: string; queryid: string;
}

let whatWeTreatLinks: WhatWeTreat[] = [
  { key: 1, link: "Diabetes Reversal", route: "/package/one-month", queryid: "" },
  { key: 2, link: "Fitness Plan", route: "/package/fitness", queryid: "" },
  { key: 3, link: "PCOD Management", route: "/package/thyroid-pcod", queryid: "" },
  // { key: 4, link: "Thyroid Reversal", route: "/package/thyroid-pcod", queryid: "" },
  // { key: 5, link: "Blood Pressure", route: "/package/blood-pressure", queryid: "" },
];

function Menu({ isDark }: { isDark?: boolean }) {
  const navigation = useRouter();
  function handleClick() {
    // const component = document.querySelector('#leadsForm');
    // component?.scrollIntoView({ behavior: 'smooth' });
    navigation.push("/contact")
  }

  const handleRoutes = (index: number) => {
    router.push({
      pathname: `${whatWeTreatLinks[index]?.route}`,
    }, undefined, { shallow: true })
  }
  return (
    <section className="px-8 my-8 max-w-screen-2xl mx-auto w-full text-black z-[3000]">
      <div
        className={`flex flex-wrap justify-between items-center  mt-2 rounded-xl flex-row py-4 px-10 shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white`}
      >
        <div className="grid grid-rows-3 grid-flow-col gap-4 p-4">
          {whatWeTreatLinks?.map((item, index) => {
            return (
              <div key={item.key} onClick={() => handleRoutes(index)}>
                <DetailPageLink item={item.link} />
              </div>
            );
          })}
        </div>
        <div className={`${styles.flexCenter} flex-col gap-2`}>
          <p className="text-2xl">Not sure where to start?</p>
          <Heading2>Get a free doctor consultation</Heading2>
          <button className="w-36 bg-primary h-10 text-white rounded-md" onClick={handleClick}>
            Book now
          </button>
        </div>
      </div>
    </section>
  );
}

export default Menu;

export const DetailPageLink = ({ item }: { item: string }) => {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      whileHover={{ y: -6 }}
      className="border-2 border-black-200 rounded-lg w-64 p-3 flex justify-between items-center cursor-pointer"
    >
      <span>{item}</span>
      <span>
        <MdOutlineAddCircle size={20} />
      </span>
    </motion.div>
  );
};
